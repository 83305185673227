'use client';

import { TypeAnimation } from 'react-type-animation';
import './Description.css';
import { Container } from '@/components/Layout/Container';
import { Subtitle } from '@/ui/Subtitle';
import { Title } from '@/ui/Title';
import { Paragraph } from '@/ui/Paragraph';
import { ArchitectureDiagram } from 'public/images/ArchitectureDiagram';

const FOR_WORDS = ['worlds', 'games', 'experiences', 'art pieces', 'experiments', 'social apps'];
const WORDS_SEQUENCE = FOR_WORDS.map((word) => {
  return [word, 2000];
}).flat();

export function Description() {
  return (
    <div className="h-full bg-black py-8">
      <Container>
        <div className="flex h-full flex-col gap-6 md:gap-12 lg:flex-row lg:items-end">
          <div className="md:flex-shrink-0">
            <Subtitle>Get started</Subtitle>
            <Title>
              <div>Redstone is for</div>
              <div>
                <span className="desktop-text">building </span>
                <span className="animate-border-pulsate border-r-redstone bg-dark-gray ml-1.5 inline-block border-r-[3px] px-3 font-bold">
                  <TypeAnimation
                    sequence={WORDS_SEQUENCE}
                    wrapper="span"
                    cursor={false}
                    repeat={Infinity}
                  />
                </span>
              </div>
            </Title>
            {/* <Image
              src={ArchitectureDiagram}
              alt="Architecture diagram"
              width={530}
              height={511}
              className="mt-8"
            /> */}

            <div className="mt-8 max-w-full md:w-[563px]">
              <ArchitectureDiagram />
            </div>
          </div>

          <div className="flex flex-col justify-between">
            <Paragraph>
              Build and ship swiftly on a robust, well-documented, and open-source stack built by
              the team at Lattice, in collaboration with Optimism.
            </Paragraph>

            <Subtitle className="mt-6">MUD</Subtitle>
            <Paragraph>
              A battle-tested framework for games and worlds to simplify and speed up your
              development.
            </Paragraph>

            <Subtitle className="mt-6">Redstone</Subtitle>
            <Paragraph>
              A Layer 2 blockchain optimized for games, worlds, and other ambitious onchain
              applications.
            </Paragraph>

            <Subtitle className="mt-6">OP Stack & Plasma Mode</Subtitle>
            <Paragraph>
              An innovative plasma data availability architecture to bring your users super low fees
              independent of blob demand, built on an open-source rollup stack powered by Optimism
              and used by OP Mainnet, Base, Zora, Redstone, etc.
            </Paragraph>
          </div>
        </div>
      </Container>
    </div>
  );
}
