import { cn } from '@/utils/cn';

export const containerMargins = 'py-10 px-[28px] xl:px-[calc((100%-1152px)/2)]';
const blogContainerMargins = 'px-[28px] md:px-[calc((100%-640px)/2)]';
const blockContainerMargins = 'px-[28px] xl:px-[calc((100%-1152px)/2)]';
const aboutContainerMargins = 'px-[28px] xl:pr-[90px] xl:pl-[calc(100%-576px)]';
const aboutRightContainerMargins = 'px-[28px] xl:pl-[90px] xl:pr-[calc(100%-576px)]';

export function Container({
  children,
  className,
  context = 'default',
}: {
  children: React.ReactNode;
  className?: string;
  context?: 'blog' | 'default' | 'block' | 'about' | 'about-right';
}) {
  return (
    <div
      className={cn(
        'flex flex-col',
        'w-full',
        context === 'blog' && blogContainerMargins,
        context === 'default' && containerMargins,
        context === 'block' && blockContainerMargins,
        context === 'about' && aboutContainerMargins,
        context === 'about-right' && aboutRightContainerMargins,
        className,
      )}
    >
      {children}
    </div>
  );
}
