import { ReactNode } from 'react';
import { cn } from '@/utils/cn';

export function Subtitle({
  className,
  children,
  ...props
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <h3 className={cn('font-mono text-lg uppercase opacity-50', className)} {...props}>
      {children}
    </h3>
  );
}
