'use client';

import { memo, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { cn } from '@/utils/cn';
import truncateEthAddress from '@/utils/truncateEthAddress';
import { Button } from '@/ui/Button';
import { timeAgo } from '@/utils/timeAgo';

const SYSTEM_ADDRESS = '0xDeaDDEaDDeAdDeAdDEAdDEaddeAddEAdDEAd0001';

const TransactionsTable = memo(function TransactionsTable() {
  // TODO: add loading
  const { data } = useQuery({
    queryKey: ['transactions'],
    queryFn: async () => {
      const response = await fetch('https://explorer.redstone.xyz/api/v2/transactions');
      return response.json();
    },
    refetchInterval: 500,
    select: (res) => res?.items,
  });

  const txs = useMemo(() => {
    if (!data) {
      return [];
    }

    return data
      .sort((txA, txB) => {
        return new Date(txB.timeStamp).getTime() - new Date(txA.timeStamp).getTime();
      })
      .filter((tx) => tx.from?.hash !== SYSTEM_ADDRESS)
      .splice(0, 8)
      .map((tx) => {
        return {
          block: tx.block,
          hash: tx.hash,
          from: tx.from?.hash,
          to: tx.to?.hash,
          gasUsed: tx.gas_used,
          usdCost: 0.001,
          status: 'confirmed',
          createdAt: timeAgo(new Date(tx.timeStamp).getTime()),
        };
      });
  }, [data]);

  return (
    <div className="flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table className="min-w-full divide-y divide-white/20">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-3 pl-[28px] pr-3 text-left font-mono text-[17px] uppercase opacity-50"
                >
                  #
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left font-mono text-[17px] uppercase opacity-50"
                >
                  From
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left font-mono text-[17px] uppercase opacity-50"
                >
                  To
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left font-mono text-[17px] uppercase opacity-50"
                >
                  Gas used
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left font-mono text-[17px] uppercase opacity-50"
                >
                  USD cost
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left font-mono text-[17px] uppercase opacity-50"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-3 py-3 text-left font-mono text-[17px] uppercase opacity-50"
                >
                  Time
                </th>
              </tr>
            </thead>

            <tbody>
              {txs.map((tx) => (
                <tr
                  key={tx.hash}
                  className={cn('animate-new-row cursor-pointer', {
                    'bg-white/10': tx.status === 'pending',
                  })}
                  onClick={() => {
                    const txUrl = `${process.env.NEXT_PUBLIC_L2_EXPLORER_URL}/tx/${tx.hash}`;
                    window.open(txUrl, '_blank');
                  }}
                >
                  <td className="whitespace-nowrap py-[22px] pl-[28px] pr-3 font-mono text-[19px] text-sm font-medium uppercase">
                    {tx.block}
                  </td>
                  <td className="whitespace-nowrap px-3 py-[22px] font-mono text-[19px] text-sm uppercase">
                    {truncateEthAddress(tx.from)}
                  </td>
                  <td className="whitespace-nowrap px-3 py-[22px] font-mono text-[19px] text-sm uppercase">
                    {truncateEthAddress(tx.to)}
                  </td>
                  <td className="whitespace-nowrap px-3 py-[22px] font-mono text-[19px] text-sm uppercase">
                    {tx.gasUsed}
                  </td>
                  <td className="whitespace-nowrap px-3 py-[22px] font-mono text-[19px] text-sm uppercase">
                    ${tx.usdCost}
                  </td>
                  <td className="whitespace-nowrap px-3 py-[22px] font-mono text-[19px] text-sm uppercase">
                    {tx.status}
                  </td>
                  <td className="whitespace-nowrap px-3 py-[22px] pr-[28px] font-mono text-[19px] text-sm uppercase lg:pr-0">
                    {tx.createdAt}{' '}
                    {tx.createdAt !== 'Now' && <span className="opacity-80">ago</span>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

export function Transactions() {
  return (
    <>
      <div className="mt-[25px] border border-white/20">
        <div className="flex justify-between p-[27px]">
          <div>
            <h3 className="font-mono text-[26px] uppercase leading-none">Transactions</h3>
            <a
              href={`${process.env.NEXT_PUBLIC_L2_EXPLORER_URL}/txs`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-redstone mt-[12px] inline-block font-mono text-[17px] uppercase"
            >
              View all →
            </a>
          </div>

          <div className="hidden items-start gap-[16px] lg:flex">
            <Button href="/deposit" external>
              Bridge
            </Button>
            <Button level="secondary" href="/data-availability" external>
              Challenges
            </Button>
            <Button level="tertiary" href={process.env.NEXT_PUBLIC_L2_EXPLORER_URL} external>
              Explorer
            </Button>
          </div>
        </div>

        <TransactionsTable />
      </div>

      <p className="mx-auto mt-[20px] font-mono text-[20px] opacity-30 md:hidden">
        ← SCROLL TO VIEW MORE →
      </p>

      <div className="mt-[30px] grid grid-cols-2 gap-[16px] md:hidden">
        <Button href="/deposit" external>
          Bridge
        </Button>
        <Button level="secondary" href="/data-availability" external>
          Challenges
        </Button>
        <div className="col-span-2 w-full">
          <Button level="tertiary" href={process.env.NEXT_PUBLIC_L2_EXPLORER_URL} external>
            Explorer
          </Button>
        </div>
      </div>
    </>
  );
}
