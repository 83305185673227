// @ts-nocheck

import { useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';

type DataPoint = { x: number; y: number };

type Props = {
  width: number;
  height: number;
  data?: unknown;
  valueFormatter?: (value: number) => string;
  translateX?: number;
};

export function MetricsChart({ width, height, data, valueFormatter, translateX = 0 }: Props) {
  const axesRef = useRef(null);
  const boundsWidth = width;
  const boundsHeight = height;

  // Y axis
  const [min, max] = d3.extent(data, (d) => d.y); // adding 2M to max
  const yScale = useMemo(() => {
    return d3
      .scaleLinear()
      .domain([0, max ?? 0])
      .range([boundsHeight, 0]);
  }, [boundsHeight, max]);

  // X axis
  const [xMin, xMax] = d3.extent(data, (d) => d.x);
  const xScale = useMemo(() => {
    return d3
      .scaleTime()
      .domain([xMin ?? new Date(), xMax ?? new Date()])
      .range([0, boundsWidth]);
  }, [boundsWidth, xMax, xMin]);

  // Render the X and Y axis using d3.js, not react
  useEffect(() => {
    const svgElement = d3.select(axesRef.current);
    svgElement.selectAll('*').remove();

    const dateFormatter = d3.timeFormat('%-m/%d');
    const xAxisGenerator = d3.axisBottom(xScale).ticks(16).tickFormat(dateFormatter);
    svgElement
      .append('g')
      .attr('transform', `translate(0, ${boundsHeight - 50})`)
      .attr('class', 'font-mono text-[17px] opacity-80')
      .call(xAxisGenerator)
      .call((g) => g.select('.domain').remove())
      // remoev every third tick
      .call((g) => g.selectAll('.tick:nth-child(2n) text').remove())
      .call((g) => g.selectAll('.tick line').remove())
      .call((g) => g.select('.tick:first-child text').remove());
    // .call((g) => g.select('.tick:last-child text').remove());

    let yAxisGenerator = d3.axisLeft(yScale).ticks(4);
    if (valueFormatter) {
      yAxisGenerator = yAxisGenerator.tickFormat(valueFormatter);
    }

    svgElement
      .append('g')
      .attr('transform', `translate(${translateX}, 0)`)
      .attr('class', 'font-mono text-[17px] opacity-80')
      .call(yAxisGenerator)
      .call((g) => g.select('.domain').remove())
      .call((g) => g.selectAll('.tick:first-child').remove())
      .call((g) => g.select('.tick:first-child text').remove())
      .call((g) => g.selectAll('.tick').attr('width', 150))
      .call((g) => g.selectAll('.tick line').attr('x2', 600).attr('class', 'opacity-20'))
      .call((g) => g.selectAll('.tick line').attr('x2', 600).attr('class', 'opacity-20'));
  }, [xScale, yScale, boundsHeight, boundsWidth, valueFormatter, translateX]);

  // Build the line
  const areaBuilder = d3
    .area<DataPoint>()
    .x((d) => xScale(d.x))
    .y1((d) => yScale(d.y))
    .y0(yScale(0));
  const areaPath = areaBuilder(data);

  // Build the line
  const lineBuilder = d3
    .line<DataPoint>()
    .x((d) => xScale(d.x))
    .y((d) => yScale(d.y));
  const linePath = lineBuilder(data);

  if (!linePath || !areaPath) {
    return null;
  }

  return (
    <div>
      <svg width={width} height={height} style={{ overflow: 'visible' }}>
        <g width={boundsWidth} height={boundsHeight}>
          <path d={areaPath} opacity={1} stroke="none" fill="#333333" />
          <path d={linePath} opacity={1} stroke="#999999" fill="none" strokeWidth={2} />
        </g>
        <g width={boundsWidth} height={boundsHeight} ref={axesRef} />
      </svg>
    </div>
  );
}
