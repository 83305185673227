export const timeAgo = (timestamp: string) => {
  const units = [
    { name: 'Y', limit: 365 * 24 * 60 * 60 * 1000, inMilliseconds: 365 * 24 * 60 * 60 * 1000 },
    { name: 'MTH', limit: 30 * 24 * 60 * 60 * 1000, inMilliseconds: 30 * 24 * 60 * 60 * 1000 },
    { name: 'D', limit: 24 * 60 * 60 * 1000, inMilliseconds: 24 * 60 * 60 * 1000 },
    { name: 'H', limit: 60 * 60 * 1000, inMilliseconds: 60 * 60 * 1000 },
    { name: 'M', limit: 60 * 1000, inMilliseconds: 60 * 1000 },
    { name: 'S', limit: 1000, inMilliseconds: 1000 },
  ];

  const diff = Date.now() - new Date(timestamp).getTime();
  if (diff < 0) {
    return 'In the future';
  }

  for (let unit of units) {
    if (diff > unit.limit) {
      const unitsAgo = Math.floor(diff / unit.inMilliseconds);
      return `${unitsAgo}${unit.name} ago`;
    }
  }

  return 'Now';
};
