'use client';

import { TypeAnimation } from 'react-type-animation';
import { Container } from '@/components/Layout/Container';
import { Button } from '@/ui/Button';
import { Paragraph } from '@/ui/Paragraph';
import { Subtitle } from '@/ui/Subtitle';
import { Title } from '@/ui/Title';

const WORDS = ['play', 'mod', 'explore', 'craft', 'assemble', 'design', 'fight', 'race'];
const WORDS_SEQUENCE = WORDS.map((word, idx) => {
  if (idx === WORDS.length - 1) return word;
  return [word, 2000];
}).flat();

export function Footer() {
  return (
    <div
      className="bg-black bg-contain bg-center bg-top bg-no-repeat text-center"
      style={{
        backgroundImage: 'url(images/footer-bg.png)',
      }}
    >
      <Container className="pb-20 pt-16">
        <Subtitle>Start your engines</Subtitle>
        <Title className="mt-2 text-[42px]">
          Come and{' '}
          <span className="animate-border-pulsate border-r-redstone bg-dark-gray inline-block border-r-[3px] bg-opacity-90 px-3 py-2 font-semibold leading-none">
            <TypeAnimation
              sequence={WORDS_SEQUENCE}
              wrapper="span"
              cursor={false}
              repeat={Infinity}
            />
          </span>{' '}
          on Redstone
        </Title>
        <Paragraph className="mx-auto mt-[32px] inline-block max-w-[680px]">
          Explore an onchain world. Win an epic battle. Mod an infinite game. Do new things you’ve
          never done before onchain.
        </Paragraph>

        <div className="mt-[32px] flex w-full flex-col items-center justify-center gap-[25px] md:flex-row">
          <Button
            level="ghost"
            className="w-full md:w-auto"
            href="https://lattice.xyz/discord"
            external
          >
            Join Discord
          </Button>
          <Button
            level="primary-red"
            className="w-full md:w-auto"
            href="https://redstone.xyz/docs/"
            external
          >
            Documentation
          </Button>
        </div>
      </Container>
    </div>
  );
}
