import { CSSProperties, ReactNode } from 'react';
import Link from 'next/link';
import { cn } from '@/utils/cn';

export function Button({
  children,
  onClick,
  href,
  external,
  className,
  level = 'primary',
  style,
}: {
  children: ReactNode;
  onClick?: () => void;
  href?: string;
  external?: boolean;
  className?: string;
  level?: 'primary' | 'primary-red' | 'secondary' | 'tertiary' | 'ghost';
  style?: CSSProperties;
}) {
  const buttonClasses = cn(
    'font-mono',
    'transition-all',
    'p-[13px] text-[18px] uppercase',
    'border border-white',
    level === 'primary-red' && 'bg-redstone text-white border-redstone hover:border-white',
    level === 'primary' && 'bg-white text-[#0C0C0C] hover:border-black',
    level === 'secondary' &&
      'text-white bg-white bg-opacity-20 border-opacity-20 hover:border-opacity-100 bg-opacity-10',
    level === 'tertiary' && 'text-white bg-transparent border-opacity-20 hover:border-opacity-100',
    level === 'ghost' &&
      'text-white bg-white bg-opacity-10 border-opacity-0 hover:border-opacity-100',
    'leading-[22px]',
    'flex flex-row items-center justify-center gap-2',
  );

  if (href) {
    return (
      <Link href={href} {...(external ? { target: '_blank', rel: 'noopener noreferrer' } : {})}>
        <div className={cn(buttonClasses, className)} style={style}>
          {children}
        </div>
      </Link>
    );
  }

  return (
    <button type="button" onClick={onClick} className={cn(buttonClasses, className)} style={style}>
      {children}
    </button>
  );
}
