import { ReactNode } from 'react';
import { cn } from '@/utils/cn';

export function Paragraph({
  className,
  children,
  ...props
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <p className={cn('mt-2 font-sans text-2xl', className)} {...props}>
      {children}
    </p>
  );
}
