import { ReactNode } from 'react';
import { cn } from '@/utils/cn';

export function Title({
  className,
  children,
  ...props
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <h2 className={cn('mt-2 text-4xl', className)} {...props}>
      {children}
    </h2>
  );
}
