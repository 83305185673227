'use client';

import { Container } from '@/components/Layout/Container';
import { Subtitle } from '@/ui/Subtitle';
import { Title } from '@/ui/Title';
import { MetricsChart } from './MetricsChart';
import { useEffect, useLayoutEffect, useState } from 'react';
import { Transactions } from './Transactions';
import styles from './Metrics.module.css';
import Image from 'next/image';
import { useQuery } from '@tanstack/react-query';
import { LoadingIcon } from 'public/icons/LoadingIcon';

const txCosts = [
  {
    game: 'Sky Strife',
    action: 'Attack a unit',
    image: '/images/games/sky-strife.jpg',
    cost: '$0.001',
  },
  {
    game: 'Biomes',
    action: 'Craft an item',
    image: '/images/games/biomes.jpg',
    cost: '<$0.001',
  },
  {
    game: 'This Cursed Machine',
    action: 'Fulfill an order',
    image: '/images/games/cursed-machine.jpeg',
    cost: '$0.001',
  },
  {
    game: 'Downstream',
    action: 'Jump a tile',
    image: '/images/games/downstream.jpg',
    cost: '<$0.001',
  },
];

export function Stats() {
  const [gameIdx, setGameIdx] = useState<number>(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setGameIdx((prevIdx: number) => (prevIdx + 1) % txCosts.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useLayoutEffect(() => {
    const updateCubeSize = () => {
      const width = document.getElementById('metric')?.offsetWidth;
      const height = document.getElementById('metric')?.offsetHeight;
      document.documentElement.style.cssText = `--cube-width: ${width}px; --cube-height: ${height}px;`;
    };
    window.addEventListener('resize', updateCubeSize);
    updateCubeSize();
    return () => window.removeEventListener('resize', updateCubeSize);
  }, []);

  return (
    <div className="mt-[40px] grid grid-cols-1 gap-[30px] sm:grid-cols-2 lg:grid-cols-4">
      <div
        id="metric"
        className="flex h-[160px] flex-col justify-between border border-white/20 px-[30px] py-[20px]"
      >
        <h3 className="font-mono text-[20px] uppercase ">Block size</h3>
        <p className="font-mono text-[38px] uppercase">
          100M <span className="opacity-50">Gas</span>
        </p>
      </div>

      <div className="flex h-[160px] flex-col justify-between border border-white/20 px-[30px] py-[20px]">
        <h3 className="font-mono text-[20px] uppercase ">Block time</h3>
        <div className="flex items-center gap-[16px] md:justify-between">
          <p className="font-mono text-[38px] uppercase">2.0S</p>
          <div className="flex items-center justify-between gap-[12px]">
            <span className="inline-block h-[8px] w-[8px] animate-pulse rounded-full bg-[#16FFAB]" />
            <p className="font-mono text-[20px] uppercase opacity-50">Live</p>
          </div>
        </div>
      </div>

      <div className="flex h-[160px] flex-col justify-between border border-white/20 py-[20px] pl-[30px] pr-[24px]">
        <h3 className="font-mono text-[20px] uppercase ">ETH Transfer Cost</h3>
        <p className="font-mono text-[38px] uppercase">&lt;$0.001</p>
      </div>

      <div className={styles.scene}>
        <div
          className={`${styles.box} 
                ${gameIdx === 0 ? styles['show-front'] : ''}
                ${gameIdx === 1 ? styles['show-left'] : ''}
                ${gameIdx === 2 ? styles['show-back'] : ''}
                ${gameIdx === 3 ? styles['show-right'] : ''}
                ${gameIdx === 4 ? styles['show-front'] : ''}
              `}
        >
          <div
            className={`relative border border-white/20 bg-black ${styles.box__face} ${styles['box__face--front']}`}
          >
            <div className="absolute left-0 top-0 z-10 h-full w-full bg-black bg-opacity-50" />
            <Image
              src={txCosts[0].image}
              alt={txCosts[0].game}
              width={265}
              height={160}
              className="z-1 absolute left-0 top-0 h-full w-full object-cover"
            />
            <div className="relative z-20 flex h-full w-full flex-col justify-between py-[20px] pl-[30px]">
              <div>
                <h3 className="font-mono text-[20px] uppercase">{txCosts[0].action}</h3>
                <p className="font-mono uppercase opacity-50">On {txCosts[0].game}</p>
              </div>
              <p className="z-1 relative font-mono text-[38px] uppercase">{txCosts[0].cost}</p>
            </div>
          </div>
          <div
            className={`relative border border-white/20 bg-black ${styles.box__face} ${styles['box__face--back']}`}
          >
            <div className="absolute left-0 top-0 z-10 h-full w-full bg-black bg-opacity-50" />
            <Image
              src={txCosts[1].image}
              alt={txCosts[1].game}
              width={265}
              height={160}
              className="absolute left-0 top-0 h-full w-full object-cover"
            />
            <div className="relative z-20 flex h-full w-full flex-col justify-between py-[20px] pl-[30px]">
              <div>
                <h3 className="font-mono text-[20px] uppercase">{txCosts[1].action}</h3>
                <p className="font-mono uppercase opacity-50">On {txCosts[1].game}</p>
              </div>
              <p className="z-1 relative font-mono text-[38px] uppercase">{txCosts[1].cost}</p>
            </div>
          </div>
          <div
            className={`relative border border-white/20 bg-black ${styles.box__face} ${styles['box__face--right']}`}
          >
            <div className="absolute left-0 top-0 z-10 h-full w-full bg-black bg-opacity-50" />
            <Image
              src={txCosts[2].image}
              alt={txCosts[2].game}
              width={265}
              height={160}
              className="absolute left-0 top-0 h-full w-full object-cover"
            />
            <div className="relative z-20 flex h-full w-full flex-col justify-between py-[20px] pl-[30px]">
              <div>
                <h3 className="font-mono text-[20px] uppercase">{txCosts[2].action}</h3>
                <p className="font-mono uppercase opacity-50">On {txCosts[2].game}</p>
              </div>
              <p className="z-1 relative font-mono text-[38px] uppercase">{txCosts[2].cost}</p>
            </div>
          </div>
          <div
            className={`relative border border-white/20 bg-black ${styles.box__face} ${styles['box__face--left']}`}
          >
            <div className="absolute left-0 top-0 z-10 h-full w-full bg-black bg-opacity-50" />
            <Image
              src={txCosts[3].image}
              alt={txCosts[3].game}
              width={265}
              height={160}
              className="absolute left-0 top-0 h-full w-full object-cover"
            />
            <div className="relative z-20 flex h-full w-full flex-col justify-between py-[20px] pl-[30px]">
              <div>
                <h3 className="font-mono text-[20px] uppercase">{txCosts[3].action}</h3>
                <p className="font-mono uppercase opacity-50">On {txCosts[3].game}</p>
              </div>
              <p className="z-1 relative font-mono text-[38px] uppercase">{txCosts[3].cost}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function MetricsGraph({
  data,
  title,
  isLoading,
  valueFormatter,
  translateX = 75,
  width = 564,
  height = 230,
}) {
  return (
    <div className="gas-graph relative h-[323px] flex-1 basis-[323px] overflow-hidden border border-white/20">
      <h4 className="absolute left-[27px] top-[20px] font-mono text-[20px] uppercase md:flex-shrink-0">
        {title}
      </h4>

      {isLoading && (
        <div className="absolute left-1/2 top-1/2">
          <LoadingIcon />
        </div>
      )}

      {!isLoading && (
        <div className="absolute bottom-0">
          <MetricsChart
            width={width}
            height={height}
            data={data}
            valueFormatter={valueFormatter}
            translateX={translateX}
          />
        </div>
      )}
    </div>
  );
}

export function Graphs() {
  const [graphWidth, setGraphWidth] = useState<number>(0);
  const select = (res: unknown) => {
    return res?.json?.chart.map((d: unknown) => ({ x: new Date(d.date), y: parseFloat(d.value) }));
  };

  const { data: gasPricesData, isLoading: gasPricesLoading } = useQuery({
    queryKey: ['gas-prices'],
    queryFn: async () => {
      const response = await fetch('/api/gas-prices');
      return response.json();
    },
    refetchInterval: 60000,
    select,
  });

  const { data: gasUsedData, isLoading: gasUsedLoading } = useQuery({
    queryKey: ['gas-used'],
    queryFn: async () => {
      const response = await fetch('/api/gas-used');
      return response.json();
    },
    refetchInterval: 60000,
    select,
  });

  useEffect(() => {
    const updateGraphSize = () => {
      const width = document.getElementsByClassName('gas-graph')[0].offsetWidth;
      setGraphWidth(width);
    };
    window.addEventListener('resize', updateGraphSize);
    updateGraphSize();
    return () => window.removeEventListener('resize', updateGraphSize);
  }, []);

  return (
    <div className="mt-[25px] flex flex-col gap-[24px] md:flex-row">
      <MetricsGraph
        data={gasUsedData}
        isLoading={gasUsedLoading}
        title="Gas used growth"
        width={graphWidth}
        valueFormatter={(d: number) => `${d / 1000000000}B`}
        translateX={75}
      />
      <MetricsGraph
        data={gasPricesData}
        isLoading={gasPricesLoading}
        title="Gas price (gwei)"
        width={graphWidth}
        translateX={100}
      />
    </div>
  );
}

export function Metrics() {
  return (
    <div className="bg-black pb-8">
      <Container>
        <Subtitle>Metrics</Subtitle>
        <Title>
          Redstone is a <span className="font-semibold">fast, reliable, secure</span> place to
          build.
        </Title>

        <Stats />
        <Graphs />
        <Transactions />
      </Container>
    </div>
  );
}
